import "../App.css";
import io from "socket.io-client";
import moment from "moment-timezone";
import bgImage from "../assets/bgImage.png";
import Blocked from "../components/Blocked";
import CeekayLogo from "../assets/CeekayLogo.png";
import Carousel from "react-material-ui-carousel";
import MarketClose from "../components/MarketClose";
import { useEffect, useMemo, useState } from "react";
import bullionStats from "../assets/bullioStats.png";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { commodityCalculation } from "../components/constants";
import EconomicNewsModal from "../components/EconomicNewsModal";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SubscriptionExpired from "../components/SubscriptionExpired";
import SubscriptionExpiringSoon from "../components/SubscriptionExpiringSoon";
import { Box, Divider, LinearProgress, Modal, Typography } from "@mui/material";
import {
  allCommodities,
  allNewses,
  findBanners,
  getGoldPriceNews,
  getLiveValueTypeForDisplay,
  getRatio,
  getSocketUrl,
  getSpread,
  getAdminProfile,
  getEconomicNews,
} from "../sevice/home";

const { makeStyles } = require("@mui/styles");

let socket = localStorage.getItem("socketUrl")
  ? io(JSON.parse(localStorage.getItem("socketUrl")))
  : null;

const socket2 = io(process.env.REACT_APP_BACKEND_URL, { path: "/latest" });
const adminId = process.env.REACT_APP_ADMIN_ID;

const useStyles = makeStyles((theme) => ({
  mainBody: {
    height: "100dvh",
    display: "flex",
    flexDirection: "column",
  },
  mainContainer: {
    width: "100%",
    height: "90vh",
    margin: "auto",
    display: "grid",
    gridTemplateColumns: "1.1fr 2fr",
    gridTemplateRows: "1fr",
    gridTemplateAreas: `
        'leftPart rightPart'
      `,
    padding: "1.5rem 2rem 1.5rem 2rem ",
    boxSizing: "border-box",
    columnGap: "2rem",
  },
  leftPart: {
    boxSizing: "border-box",
    gridArea: "leftPart",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  timeArea: {
    height: "26.5%",
    boxSizing: "border-box",
    borderRadius: "5px",
    display: "flex",
    color: "#fff",
    gap: 4,
    justifyContent: "space-between",
    paddingBottom: "19px",
  },
  banner: {
    height: "40%",
    boxSizing: "border-box",
  },
  bannerImg: {
    width: "100%",
    height: "100%",
    objectFit: "fill",
    overflow: "hidden",
  },
  spotRate: {
    height: "47%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    color: "#16341B",
    gap: ".6rem",
    borderRadius: "5px",
    color: "#FFFFFF",
    justifyContent: "end",
  },
  spotRateBox: {
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  liveRateValue: {
    border: "1px solid #AC7F81",
    color: "#FFFFFF",
    borderRadius: "8px",
    width: "9.3vw",
    height: "7.3vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color .5s ease",
  },
  liveRateValueSilver: {
    border: "1px solid #AC7F81",
    color: "#FFFFFF",
    borderRadius: "8px",
    width: "9.3vw",
    height: "6.5vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color .5s ease",
  },
  rightPart: {
    gridArea: "rightPart",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
    flexDirection: "column",
    gap: 7,
  },
  logoArea: {
    height: "22%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logoImage: {
    width: "90%",
    alignItems: "center",
    height: "90%",
    objectFit: "contain",
  },
  commoditieTable: {
    gridArea: "commoditieTable",
    height: "60%",
    display: "flex",
    boxSizing: "border-box",
    flexDirection: "column",
    borderRadius: "5px",
  },
  table: {
    gridArea: "table",
    boxSizing: "border-box",
    color: "#AD1457",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  tabeHeader: {
    gridArea: "tabeHeader",
    backgroundColor: "#C3343B",
    color: "white",
    flex: "1",
    display: "flex",
    justifyContent: "center",
    borderTopRightRadius: "20px",
    borderTopLeftRadius: "20px",
    alignItems: "center",
    height: "15%",
  },
  dollarBox: {
    padding: "0px 6px 0px 6px",
    fontSize: ".8vw",
    backgroundColor: "#C3343B",
    borderRadius: "5px",
  },
  tableContent: {
    gridArea: "tableContent",
    display: "flex",
    height: "85%",
    flexDirection: "column",
    justifyContent: "space-between",
    overflowX: "scroll",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    border: " 1px solid #AC7F81",
    backgroundColor: "#59171A",
    borderRadius: "0 0 15px 15px",
  },
  tableRowColumn: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
    justifyContent: "center",
    fontSize: "2vw",
  },
  tableRow1: {
    flexBasis: 0,
    minHeight: "19.8%",
    flexGrow: 1,
    display: "flex",
  },
  ratio: {
    height: "15%",
    gridArea: "stat",
    borderRadius: "20px",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#59171A",
    border: " 1px solid #AC7F81",
  },
  bullionStatsImg: {
    width: "10vw",
    height: "auto",
  },
  updates: {
    padding: "0rem 2rem 1.5rem 2rem",
    display: "flex",
    alignItems: "center",
    height: "9%",
    color: "#FFFFFF",
    boxSizing: "border-box",
    width: "100%",
  },
  updatesHeader: {
    backgroundColor: "#C3343B",
    height: "100%",
    width: "15%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  updatesContent: {
    display: "flex",
    width: "70%",
    alignItems: "center",
    height: "100%",
    background: "#FFFFFE",
    color: "#200C02",
    flex: 1,
  },
  blur: {
    filter: "blur(8px)",
  },
}));

const Home = () => {
  const classes = useStyles();
  const [adminData, setAdminData] = useState();
  const [openSubscriptionExpireSoon, setOpenSubscriptionExpireSoon] =
    useState(false);
  const [openSubscriptionExpierd, setOpenSubscriptionExpierd] = useState(false);
  const [openBlocked, setOpenBlocked] = useState(false);
  const [isMarketOpen, setIsMarketOpen] = useState(true);
  const [render, setRender] = useState(true);

  const [gold, setGold] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [silver, setSilver] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [ratio, setRatio] = useState({
    Buyers: "0%",
    chgValue: "+0%",
    commodity: "Gold",
  });
  const [displayBidOrBuy, setDisplayBidOrBuy] = useState({
    bidOrBuy: "Bid",
    askOrSell: "Ask",
  });
  const [spread, setSpread] = useState({
    goldAskSpread: 0,
    goldBidSpread: 0,
    silverAskSpread: 0,
    silverBidSpread: 0,
    goldLowSpread: 0,
    goldHighSpread: 0,
    silverLowSpread: 0,
    silverHighSpread: 0,
  });
  const [news, setNews] = useState([]);
  const [banners, setBanners] = useState([]);
  const [goldNews, setGoldNews] = useState({});
  const [commodities, setCommodities] = useState([]);

  ////////////////////// Block //////////////////////////
  const handleOpenBlocked = () => setOpenBlocked(true);
  const handleCloseBlocked = () => {
    window.location.reload();
  };

  ///////////////////// Subscription Expierd /////////////////
  const handleOpenSubscriptionExpierd = () => setOpenSubscriptionExpierd(true);
  const handleCloseSubscriptionExpierd = () => window.location.reload();

  ///////////////////// Subscription ExpierSoon /////////////////////
  const handleExpireSoonOpen = () => setOpenSubscriptionExpireSoon(true);
  const handleOpenExpireSoonClose = () => setOpenSubscriptionExpireSoon(false);

  ///////////////////// Check is Market Closed  /////////////////////
  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment().tz(adminData?.time_zone);
      const dayOfWeek = now.day();
      const hour = now.hour();
      if (
        (dayOfWeek >= 1 && dayOfWeek <= 5 && hour >= 1 && hour < 2) || // Monday to Friday, 1AM to 2AM
        (dayOfWeek === 6 && hour >= 1) || // Saturday, 1AM onwards
        dayOfWeek === 0 || // Sunday
        (dayOfWeek === 1 && hour < 2) // Monday, before 2AM
      ) {
        setIsMarketOpen(false);
      } else {
        setIsMarketOpen(true);
      }
    }, 1000); // Check every minute

    return () => clearInterval(interval);
  }, [adminData?.time_zone]);

  useMemo(() => {
    if (socket && render) {
      setRender(false);
      socket.on("connect", () => {});
      socket.on("disconnect", () => {});
      socket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err.message}`);
      });
      socket.on("gold-rate-change", (data) => {
        // setIsMarketOpen(data.data.isMarketOpen);
        setGold((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
      socket.on("silver-rate-change", (data) => {
        setSilver((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
    }
  }, [socket]);

  useEffect(() => {
    socket2.on("connect", () => {});
    socket2.on("disconnect", () => {});
    socket2.emit("join_room", { roomId: adminId });
    socket2.on("commodityRerender", (data) => {
      handleFindCommodities();
    });
    socket2.on("newsRerender", (data) => {
      hadlefetchNews();
    });
    socket2.on("spreadRerender", (data) => {
      handleFindSpread();
    });
    socket2.on("loginChecking", (data) => {
      // handleloginChecking();
    });
    socket2.on("mediaRerender", (data) => {
      handelFindBanners();
    });
    socket2.on("adminBlockRerender", (data) => {
      handleFindAdminProfile();
    });
    socket2.on("socketRerender", (data) => {
      localStorage.removeItem("socketUrl");
      window.location.reload();
    });
    socket2.on("reloadRender", (data) => {
      window.location.reload();
    });
    socket2.on("upgradeRerender", (data) => {
      handleFindAdminProfile();
    });
    socket2.on("bidAndAskTitleRerender", (data) => {
      handleFindLiveValueTypeForDisplay();
    });
    socket2.on("economicNewsRender", (data) => {
      handleFindEconomicNews();
    });
  }, []);

  const handleFindCommodities = async () => {
    const res = await allCommodities();
    if (Array.isArray(res.commodities)) {
      setCommodities(res.commodities);
    }
  };
  const handleFindSpread = async () => {
    const res = await getSpread();
    if (res) {
      setSpread(res);
    }
  };
  const hadlefetchNews = async () => {
    const res = await allNewses();
    if (Array.isArray(res)) {
      setNews(res);
    }
  };
  const handleFindGoldPriceNews = async () => {
    const res = await getGoldPriceNews();
    if (res.length > 0) setGoldNews(res[0]);
  };

  const handelFindBanners = async () => {
    const res = await findBanners();
    if (Array.isArray(res)) {
      setBanners(res);
    }
  };
  const handleFindLiveValueTypeForDisplay = async () => {
    const res = await getLiveValueTypeForDisplay();
    if (res) {
      setDisplayBidOrBuy(res);
    }
  };
  const handleFindRatio = async () => {
    const res = await getRatio();
    if (res?.data) {
      setRatio(res.data);
    }
  };
  const handleFindSocketUrl = async () => {
    const res = await getSocketUrl();
    if (res) {
      socket = io(res?.socketURL);
      localStorage.setItem("socketUrl", JSON.stringify(res?.socketURL));
    }
  };

  const handleFindAdminProfile = async () => {
    const res = await getAdminProfile();
    setAdminData(res);
    if (!res.status || res.isBlocked) {
      handleOpenBlocked();
    } else if (!res.isBlocked && openBlocked) {
    } else {
      handlecheckSubscriptionExpierd(res);
    }
  };

  const handlecheckSubscriptionExpierd = (data) => {
    const givenDate = new Date(data?.package.expire_date);
    let a = givenDate.toISOString();
    let b = new Date().toISOString();
    if (a < b) {
      handleOpenSubscriptionExpierd();
    }
  };

  useEffect(() => {
    if (adminData) {
      const interval = setInterval(() => {
        checkSubscriptionExpireSoon(adminData);
      }, 60 * 60 * 1000);
      return () => clearInterval(interval);
    }
  }, [adminData]);

  const checkSubscriptionExpireSoon = (data) => {
    const givenDate = new Date(data?.package.expire_date);
    const oneMonthAgo = new Date(givenDate);
    oneMonthAgo.setDate(givenDate.getDate() - 30);
    let a = oneMonthAgo.toISOString();
    let b = new Date().toISOString();
    if (b >= a) {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      if (currentHour === 10) {
        handleExpireSoonOpen();
      }
    }
  };

  useEffect(() => {
    handleFindCommodities();
    handleFindSpread();
    hadlefetchNews();
    handelFindBanners();
    handleFindLiveValueTypeForDisplay();
    handleFindRatio();
    handleFindSocketUrl();
    handleFindAdminProfile();
    handleFindEconomicNews();
    handleFindGoldPriceNews();
  }, []);

  const [economicNews, setEconomicNews] = useState([]);
  const [openNews, setOpenNews] = useState(false);
  const handleOpenopenNews = () => setOpenNews(true);
  const handleCloseopenNews = () => setOpenNews(false);

  const [curEconomicNews, setCurEconomicNews] = useState([]);
  const handleFindEconomicNews = async () => {
    const res = await getEconomicNews();
    setEconomicNews(res);
  };

  useEffect(() => {
    const timeInterwell = setInterval(() => {
      economicNewsFinding();
    }, 1000 * 60 * 1);
    economicNewsFinding();
    return () => clearInterval(timeInterwell);
  }, [economicNews]);

  const economicNewsFinding = () => {
    if (economicNews.length) {
      const currentTime = new Date(new Date().toUTCString());
      const oneHourAgo = new Date(currentTime);
      oneHourAgo.setHours(currentTime.getHours() + 1);
      oneHourAgo.setSeconds(0);
      oneHourAgo.setMilliseconds(0);

      const currentTimeMin = new Date(new Date().toUTCString());
      const fiveMinAgo = new Date(currentTimeMin);
      fiveMinAgo.setMinutes(currentTimeMin.getMinutes());
      fiveMinAgo.setSeconds(0);
      fiveMinAgo.setMilliseconds(0);

      let objectBeforeFiveMin = economicNews.filter((obj) => {
        let utcDate = new Date(obj.date);
        let timeDiff =
          new Date(
            utcDate?.toLocaleString("en-US", { timeZone: "UTC" })
          ).getTime() - fiveMinAgo.getTime();

        let minutesDiff = Math.floor(timeDiff / (1000 * 60));
        return minutesDiff <= 5 && minutesDiff >= 0;
      });
      let objectBeforeOneHour = economicNews.filter((obj) => {
        let utcDate = new Date(obj.date);
        let timeDiff =
          new Date(
            utcDate?.toLocaleString("en-US", { timeZone: "UTC" })
          ).getTime() - oneHourAgo.getTime();

        let minutesDiff = Math.floor(timeDiff / (1000 * 60));
        return minutesDiff <= 5 && minutesDiff >= -5;
      });
      if (objectBeforeFiveMin.length) {
        objectBeforeFiveMin = objectBeforeFiveMin?.sort(
          (a, b) => b.priority - a.priority
        );
        setCurEconomicNews(objectBeforeFiveMin);
        handleOpenopenNews();
      } else if (objectBeforeOneHour.length) {
        objectBeforeOneHour = objectBeforeOneHour?.sort(
          (a, b) => b.priority - a.priority
        );
        setCurEconomicNews(objectBeforeOneHour);
        handleOpenopenNews();
      } else {
        setCurEconomicNews([]);
        handleCloseopenNews();
      }
    } else {
      setCurEconomicNews([]);
    }
  };

  const formatDate = () => {
    return moment(new Date()).format("DD MMM YYYY");
  };
  const formatday = () => {
    return moment(new Date()).format("dddd");
  };
  const formatTime = () => {
    return new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const progress = Number(ratio?.Buyers?.match(/\d+/)[0]);
  const buyersColor = "#2B80BE";
  const sellersColor = "#D1172D";
  const progressStyle = { backgroundColor: sellersColor };
  const barStyle = { backgroundColor: buyersColor };

  return (
    <Box
      style={{
        backgroundImage: `url(${bgImage})`,
        width: "100dvw",
        height: "100dvh",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
      className={openSubscriptionExpierd && classes.blur}
    >
      <Box className={classes.mainBody}>
        <Box className={classes.mainContainer}>
          {!isMarketOpen && <MarketClose timeZone={adminData?.time_zone} />}
          <Box className={classes.leftPart}>
            <Box className={classes.timeArea}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <AccessTimeIcon
                  sx={{
                    fontSize: "3vw",
                    color: "#300703",
                    backgroundColor: "white",
                    borderRadius: "50%",
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "2.4vw",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {formatTime()}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <CalendarMonthIcon
                  sx={{
                    fontSize: "2.7vw",
                    color: "#300703",
                    backgroundColor: "white",
                    borderRadius: "50%",
                    p: 0.5,
                  }}
                />
                <Box>
                  <Typography
                    sx={{
                      fontSize: "1.5vw",
                      letterSpacing: "2.2px",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      fontWeight: "bold",
                    }}
                  >
                    {formatday()
                      .split("")
                      .map((item, index) => (
                        <span key={index}>{item.toUpperCase()}</span>
                      ))}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.2vw",
                      mt: -1,
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      letterSpacing: "4px",
                      gap: 1,
                    }}
                  >
                    {formatDate()
                      .split(" ")
                      .map((item, index) => (
                        <span key={index}>{item.toUpperCase()}</span>
                      ))}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box className={classes.banner}>
              <Carousel
                animation="fade" // Set the animation type to slide
                navButtonsAlwaysVisible={false} // Show navigation buttons always
                interval={10000}
                indicatorContainerProps={{
                  style: {
                    display: "none",
                  },
                }}
                sx={{
                  height: "100%",
                  borderRadius: "2px",
                }}
                transitionTime={3000} // Adjust the transition time to control the speed of the fade effect
                autoPlay
                duration={2000}
              >
                {banners?.map((banner, i) => (
                  <img
                    style={{
                      width: "100%",
                      height: "29.5vh",
                      objectFit: "cover",
                      borderRadius: "20px",
                      boxSizing: "border-box",
                      border: "1px solid #AC7F81",
                    }}
                    src={banner.imageUrl}
                    alt="banner"
                  />
                ))}
              </Carousel>
            </Box>
            <Box className={classes.spotRate}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "20%",
                  borderBottom: "1px solid #AC7F81",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.4vw",
                    fontWeight: "bold",
                    flexBasis: 0,
                    flexGrow: 0.8,
                    flexShrink: 1,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  SPOT RATE
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    flexBasis: 0,
                    flexGrow: 1,
                    flexShrink: 1,
                  }}
                >
                  {" "}
                  <Typography className={classes.dollarBox}>$</Typography>
                  <Typography sx={{ fontSize: "1.4vw", fontWeight: "bold" }}>
                    {displayBidOrBuy?.bidOrBuy?.toUpperCase()}
                  </Typography>
                  <Typography style={{ fontSize: "1.2vw" }}>oz</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    flexBasis: 0,
                    flexGrow: 1,
                    flexShrink: 1,
                  }}
                >
                  <Typography className={classes.dollarBox}>$</Typography>
                  <Typography sx={{ fontSize: "1.4vw", fontWeight: "bold" }}>
                    {displayBidOrBuy?.askOrSell?.toUpperCase()}
                  </Typography>
                  <Typography style={{ fontSize: "1.2vw" }}>oz</Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  height: "35%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.8vw",
                    fontWeight: "bold",
                    p: 0,
                    flexBasis: 0,
                    flexGrow: 0.8,
                    flexShrink: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    mb: 3,
                  }}
                >
                  GOLD
                </Typography>

                <Box className={classes.spotRateBox}>
                  <Box
                    className={classes.liveRateValue}
                    sx={{
                      backgroundColor:
                        Number(gold?.cur?.bid).toFixed(2) <
                        Number(gold?.pre?.bid).toFixed(2)
                          ? "#D1172D"
                          : Number(gold?.cur?.bid).toFixed(2) >
                            Number(gold?.pre?.bid).toFixed(2)
                          ? "#2B80BE"
                          : "",
                    }}
                  >
                    <Typography sx={{ fontSize: "2vw", fontWeight: "bold" }}>
                      {(
                        Number(spread?.goldBidSpread) + Number(gold?.cur?.bid)
                      ).toFixed(2)}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: ".7vw",
                        background: "#D1172D",
                        borderRadius: "5px",
                        width: "3rem",
                        textAlign: "center",
                        padding: 1,
                      }}
                    >
                      Low
                    </Typography>
                    <Typography style={{ fontSize: "1vw" }}>
                      {(
                        Number(spread.goldLowSpread) +
                        Number(gold?.cur?.lowPrice)
                      ).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>

                <Box className={classes.spotRateBox}>
                  <Box
                    className={classes.liveRateValue}
                    sx={{
                      backgroundColor:
                        Number(gold?.cur?.ask).toFixed(2) <
                        Number(gold?.pre?.ask).toFixed(2)
                          ? "#D1172D"
                          : Number(gold?.cur?.ask).toFixed(2) >
                            Number(gold?.pre?.ask).toFixed(2)
                          ? "#2B80BE"
                          : "",
                    }}
                  >
                    <Typography sx={{ fontSize: "2vw", fontWeight: "bold" }}>
                      {(
                        Number(spread?.goldAskSpread) + Number(gold?.cur?.ask)
                      ).toFixed(2)}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: ".7vw",
                        background: "#3DA1EA",
                        borderRadius: "5px",
                        width: "3rem",
                        textAlign: "center",
                        padding: 1,
                      }}
                    >
                      High
                    </Typography>
                    <Typography style={{ fontSize: "1vw" }}>
                      {(
                        Number(spread?.goldHighSpread) +
                        Number(gold?.cur?.highPrice)
                      ).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  height: "35%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.4vw",
                    flexBasis: 0,
                    flexGrow: 0.8,
                    flexShrink: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    mb: 3,
                  }}
                >
                  SILVER
                </Typography>

                <Box className={classes.spotRateBox}>
                  <Box
                    className={classes.liveRateValueSilver}
                    sx={{
                      backgroundColor:
                        Number(silver?.cur?.bid).toFixed(3) <
                        Number(silver?.pre?.bid).toFixed(3)
                          ? "#D1172D"
                          : Number(silver?.cur?.bid).toFixed(3) >
                            Number(silver?.pre?.bid).toFixed(3)
                          ? "#2B80BE"
                          : "",
                    }}
                  >
                    <Typography sx={{ fontSize: "1.8vw" }}>
                      {(
                        Number(spread?.silverBidSpread) +
                        Number(silver?.cur?.bid)
                      ).toFixed(3)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: ".7vw",
                        background: "#D1172D",
                        borderRadius: "5px",
                        width: "3rem",
                        padding: 1,
                        textAlign: "center",
                      }}
                    >
                      Low
                    </Typography>
                    <Typography style={{ fontSize: "1vw" }}>
                      {(
                        Number(spread.silverLowSpread) +
                        Number(silver?.cur?.lowPrice)
                      ).toFixed(3)}
                      &nbsp;
                    </Typography>
                  </Box>
                </Box>

                <Box className={classes.spotRateBox}>
                  <Box
                    className={classes.liveRateValueSilver}
                    sx={{
                      backgroundColor:
                        Number(silver?.cur?.ask).toFixed(3) <
                        Number(silver?.pre?.ask).toFixed(3)
                          ? "#D1172D"
                          : Number(silver?.cur?.ask).toFixed(3) >
                            Number(silver?.pre?.ask).toFixed(3)
                          ? "#2B80BE"
                          : "",
                    }}
                  >
                    <Typography sx={{ fontSize: "1.8vw" }}>
                      {(
                        Number(spread?.silverAskSpread) +
                        Number(silver?.cur?.ask)
                      ).toFixed(3)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: ".7vw",
                        background: "#3DA1EA",
                        borderRadius: "5px",
                        width: "3rem",
                        padding: 1,
                        textAlign: "center",
                      }}
                    >
                      High
                    </Typography>
                    <Typography style={{ fontSize: "1vw" }}>
                      {(
                        Number(spread?.silverHighSpread) +
                        Number(silver?.cur?.highPrice)
                      ).toFixed(3)}
                      &nbsp;
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.rightPart}>
            <Box className={classes.logoArea}>
              <Box sx={{ width: "50%", height: "100%" }}>
                <img
                  className={classes.logoImage}
                  src={CeekayLogo}
                  alt="leenLogo"
                />
              </Box>
            </Box>

            <Box className={classes.commoditieTable}>
              <Box className={classes.table}>
                <Box className={classes.tabeHeader}>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "2vw",
                      fontWeight: "bold",
                    }}
                  >
                    COMMODITY
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "2vw",
                      fontWeight: "bold",
                    }}
                  >
                    WEIGHT
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "2vw",
                      fontWeight: "bold",
                    }}
                  >
                    BUY
                    <Typography sx={{ fontSize: "0.9vw", mt: 2.2 }}>
                      &nbsp;AED
                    </Typography>
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "2vw",
                      fontWeight: "bold",
                    }}
                  >
                    SELL
                    <Typography sx={{ fontSize: "0.9vw", mt: 2.2 }}>
                      &nbsp;AED
                    </Typography>
                  </Typography>
                </Box>

                <Box className={classes.tableContent}>
                  {commodities?.map((commodity, idx) => {
                    const words = commodity.commodity_title.split(" ");
                    return (
                      <>
                        <Box
                          key={idx}
                          className={classes.tableRow1}
                          sx={{
                            maxHeight: commodities.length > 6 ? "17%" : "",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "1.3vw",
                              fontWeight: "bold",
                              justifyContent: "left",
                            }}
                            className={classes.tableRowColumn}
                          >
                            {words?.map((word, index) => {
                              if (index === 0) {
                                return (
                                  <span
                                    key={index}
                                    style={{
                                      fontSize: "2vw",
                                      fontWeight: "bold",
                                      paddingLeft: "30px",
                                    }}
                                  >
                                    {word.toUpperCase()}
                                  </span>
                                );
                              } else {
                                return (
                                  <span
                                    key={index}
                                    style={{
                                      fontSize: "1vw",
                                      marginTop: "0.2rem",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    &nbsp;{word.toUpperCase()}
                                  </span>
                                );
                              }
                            })}
                            &nbsp;
                            {commodity.unitLabel === "TTB"
                              ? ""
                              : commodity.displayPurity}
                          </Typography>
                          <Box
                            className={classes.tableRowColumn}
                            sx={{
                              justifyContent: "left",
                            }}
                          >
                            <Typography
                              style={{ fontSize: "2vw", fontWeight: "bold" }}
                              sx={{
                                paddingLeft: { lg: "4.8vw", md: "4.2vw" },
                              }}
                            >
                              {commodity.unit} {commodity.unitLabel}
                            </Typography>
                          </Box>
                          <Box
                            className={classes.tableRowColumn}
                            sx={{
                              justifyContent: "left",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "2vw",
                                fontWeight: "bold",
                              }}
                              sx={{
                                paddingLeft: { lg: "4.4vw", md: "4vw" },
                              }}
                            >
                              {!gold?.cur?.bid
                                ? "0.00"
                                : commodityCalculation(
                                    commodity.commodity_title === "Silver"
                                      ? silver?.cur?.bid
                                      : gold?.cur?.bid,
                                    commodity.commodity_title === "Silver"
                                      ? spread?.silverBidSpread
                                      : spread?.goldBidSpread,
                                    commodity?.buy_premium,
                                    3.674,
                                    commodity.purity,
                                    commodity.unit,
                                    commodity.unitLabel === "TTB"
                                      ? 116.64
                                      : commodity.unitLabel === "KG"
                                      ? 1000
                                      : commodity.unitLabel === "OZ"
                                      ? 31.1
                                      : commodity.unitLabel === "TOLA"
                                      ? 11.7
                                      : 1,
                                    commodity.buy_charge
                                  )}
                            </Typography>
                          </Box>
                          <Box
                            className={classes.tableRowColumn}
                            sx={{
                              justifyContent: "left",
                            }}
                          >
                            <Typography
                              style={{ fontSize: "2vw", fontWeight: "bold" }}
                              sx={{
                                paddingLeft: { lg: "3.8vw", md: "3.5vw" },
                              }}
                            >
                              {!gold?.cur?.bid
                                ? "0.00"
                                : commodityCalculation(
                                    commodity.commodity_title === "Silver"
                                      ? silver?.cur?.ask
                                      : gold?.cur?.ask,
                                    commodity.commodity_title === "Silver"
                                      ? spread.silverAskSpread
                                      : spread.goldAskSpread,
                                    commodity.premium,
                                    3.674,
                                    commodity.purity,
                                    commodity.unit,
                                    commodity.unitLabel === "TTB"
                                      ? 116.64
                                      : commodity.unitLabel === "KG"
                                      ? 1000
                                      : commodity.unitLabel === "OZ"
                                      ? 31.1
                                      : commodity.unitLabel === "TOLA"
                                      ? 11.7
                                      : 1,
                                    commodity.charges
                                  )}
                            </Typography>
                          </Box>
                        </Box>
                        <Divider sx={{ bgcolor: "#AC7F81", mx: 2 }} />
                      </>
                    );
                  })}
                </Box>
              </Box>
            </Box>
            <Box className={classes.ratio}>
              <Box
                sx={{
                  width: "80%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    px: 7,
                    py: 1,
                    boxSizing: "border-box",
                  }}
                >
                  <Typography sx={{ fontSize: ".9vw" }}>BUYERS</Typography>
                  <Typography
                    sx={{
                      color: "#F8FBFF",
                      fontSize: ".9vw",
                      color:
                        Number(ratio?.chgValue.replace("%", "")) >= 0
                          ? "#2B80BE"
                          : "#D1172D",
                    }}
                  >
                    {ratio?.chgValue}
                  </Typography>
                  <Typography sx={{ fontSize: ".9vw" }}>SELLERS</Typography>
                </Box>
                <Box sx={{ width: "90%" }}>
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    style={progressStyle}
                    sx={{
                      "& .MuiLinearProgress-bar": barStyle,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    px: 7,
                    py: 1,
                    boxSizing: "border-box",
                  }}
                >
                  <Typography sx={{ fontSize: ".9vw", color: "#2B80BE" }}>
                    {ratio?.Buyers}
                  </Typography>
                  <Box sx={{ mt: 1 }}>
                    <a href="https://www.bullionstats.com/">
                      <img
                        className={classes.bullionStatsImg}
                        src={bullionStats}
                        alt="bullionStats"
                      />
                    </a>
                  </Box>

                  <Typography sx={{ fontSize: ".9vw", color: "#D1172D" }}>
                    {100 - Number(ratio?.Buyers?.match(/\d+/)[0])}%
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.updates}>
          <Box
            className={classes.updatesHeader}
            sx={{
              minWidth: { lg: "180px", md: "135px" },
            }}
          >
            <Typography
              sx={{
                fontSize: ".9vw",
                textAlign: "center",
                letterSpacing: "2px",
              }}
            >
              CEEKAY
              <br />
              <Box
                style={{
                  fontWeight: "bold",
                  marginTop: "-7px",
                  fontSize: "1.1vw",
                  letterSpacing: "5px",
                }}
              >
                NEWS
              </Box>
            </Typography>
          </Box>
          <Box className={classes.updatesContent}>
            <marquee
              style={{
                height: "60%",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box sx={{ display: "flex" }}>
                {!adminData?.isNewsEnable &&
                Object.keys(goldNews).length > 0 ? (
                  <Box
                    style={{
                      fontSize: "1.6vw",
                      fontFamily: "poppins",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <span style={{ fontWeight: 500 }}>Gold Price News</span>
                    &nbsp;&nbsp;:&nbsp;&nbsp;<span>{goldNews?.title}</span>
                  </Box>
                ) : (
                  news?.map((item, index) => {
                    return (
                      <Box
                        key={item._id}
                        style={{
                          fontSize: "1.5vw",
                          fontFamily: "poppins",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <span style={{ fontWeight: 500 }}>
                          {item.newsTitle}
                        </span>
                        &nbsp;&nbsp;:&nbsp;&nbsp;<span>{item.newsDetails}</span>
                        <span
                          style={{ paddingRight: "50px", paddingLeft: "50px" }}
                        >
                          {index === news.length - 1 ? "" : "|"}
                        </span>
                      </Box>
                    );
                  })
                )}
              </Box>
            </marquee>
          </Box>
          <Box
            className={classes.updatesHeader}
            sx={{
              backgroundColor: "#C3343B",
              fontSize: ".8vw",
              px: 2,
              fontFamily: "poppins",
            }}
          >
            www.ceekaygoldanddiamonds.com
          </Box>
        </Box>
      </Box>
      <Modal open={openBlocked} closeAfterTransition>
        <Blocked handleClose={handleCloseBlocked} />
      </Modal>
      <Modal open={openSubscriptionExpierd} closeAfterTransition>
        <SubscriptionExpired handleClose={handleCloseSubscriptionExpierd} />
      </Modal>
      <Modal
        open={openSubscriptionExpireSoon}
        onClose={handleOpenExpireSoonClose}
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.1)", // Adjust the color and opacity as needed
            backdropFilter: "none", // This disables the blur effect
          },
        }}
      >
        <SubscriptionExpiringSoon
          date={adminData?.package.expire_date}
          handleClose={handleOpenExpireSoonClose}
        />
      </Modal>
      <Modal
        open={openNews}
        onClose={handleCloseopenNews}
        closeAfterTransition
        hideBackdrop
      >
        <EconomicNewsModal
          handleClose={handleCloseopenNews}
          curEconomicNews={curEconomicNews}
        />
      </Modal>
    </Box>
  );
};

export default Home;
